import React, { useEffect, useState } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  Spinner,
  Text,
  MenuList,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
} from '@chakra-ui/react'
import { useAppSelector } from '../../hooks'
import {
  reduceSelectedLaborCategory,
  selectLaborItemList,
  selectLaborServiceList,
  selectLaborTierList,
  selectSelectedLaborCategory,
} from '../../data/labor/slice'
import {
  dispatchCreateLaborServices,
  dispatchDeleteLaborCategories,
  dispatchListLaborItems,
  dispatchListLaborServices,
  dispatchListLaborTiers,
} from '../../data/labor/api'

import { PageHeader } from '../../mini-lib/page-header/PageHeader'
import { PageLayout } from '../../mini-lib/layouts/PageLayout'
import { LaborTable } from './LaborTable'
import { dispatchListSalonMembers } from '../../data/user/api'
import { UseQueryParams } from '../../mini-lib/utils/basic'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { selectMemberList } from '../../data/user/slice'
import { ROUTES } from '../../appRoutes'
import { EmptyBox } from '../../mini-lib/empty/EmptyBox'
import { Gap } from '../../mini-lib/gap/Gap'
import { ALL_LABOR_CATEGORIES, MOCK_CATEGORIES } from '../../data/labor/constants'
import { MaterialIcon } from '../../mini-lib/icons/MaterialIcon'
import { COLORS } from '../../mini-lib/theme/colors'
import { UseViewSize } from '../../core/UseViewSize'
import { SelectInput, TextInput } from '../../mini-lib'
import { APILaborServiceCreate } from '../../data/labor/interfaces'
import { SearchFilter } from '../../mini-lib/filters/SearchFilter'

export const LaborPage = () => {
  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()
  const breadcrumbs = [{ label: 'Home', url: generatePath(ROUTES.home, { salonId }) }, { label: 'Labors' }]
  const dispatch = useDispatch()
  const history = useHistory()
  const queryParams: any = UseQueryParams()
  const empty = queryParams.get('empty')
  const showTable = queryParams.get('showTable')
  const items = useAppSelector(selectLaborItemList)
  const tiers = useAppSelector(selectLaborTierList)
  const services = useAppSelector(selectLaborServiceList)
  const members = useAppSelector(selectMemberList)

  const itemsLoaded = !!items
  const tiersLoaded = !!tiers
  const servicesLoaded = !!services
  const membersLoaded = !!members

  const [isCreateMenuVisible, setIsCreateMenuVisible] = useState(false)

  useEffect(() => {
    if (!itemsLoaded) {
      dispatch(dispatchListLaborItems(token, salonId))
    }
  }, [dispatch, itemsLoaded, token, salonId])

  useEffect(() => {
    if (!tiersLoaded) {
      dispatch(dispatchListLaborTiers(token, salonId))
    }
  }, [dispatch, tiersLoaded, token, salonId])

  useEffect(() => {
    if (!servicesLoaded) {
      dispatch(dispatchListLaborServices(token, salonId))
    }
  }, [dispatch, servicesLoaded, token, salonId])

  useEffect(() => {
    if (!membersLoaded) {
      dispatch(dispatchListSalonMembers(token, salonId))
    }
  }, [dispatch, membersLoaded, token, salonId])

  const goToOnboard = () => {
    history.push(generatePath(ROUTES.laborOnboard, { salonId }))
  }

  // 1. make sure all data is loaded
  // 2. make data at least one type of data exists
  // 3. if show empty exists always show empty
  // 4. if show table exists always show the table
  const show = !empty && tiers && services && items && (tiers.length > 0 || services.length > 0 || showTable)
  const showEmpty = empty || (tiers && services && tiers.length === 0 && services.length === 0)
  const { isMobile } = UseViewSize()
  // todo: get this from selector
  const allCategories = MOCK_CATEGORIES
  return (
    <PageLayout
      variant="full"
      header={
        <PageHeader
          title="Services"
          breadcrumbs={breadcrumbs}
          showHelp={false}
          actions={
            <Flex align="center" gridGap="12px">
              <Button variant="round" colorScheme="brand.midnight" onClick={() => setIsCreateMenuVisible(true)}>
                Create New Menu
              </Button>
            </Flex>
          }
        />
      }
      content={
        <>
          {isCreateMenuVisible && (
            <CreateMenuModal showModal={isCreateMenuVisible} setShowModal={setIsCreateMenuVisible} />
          )}
          {/* table */}
          {show && (
            <Flex w="100%" gridGap="24px" h="100vh" wrap="wrap">
              <Flex w={isMobile ? '100%' : '240px'} justify={isMobile ? 'center' : 'start'}>
                <CategoryMenuList categories={allCategories} />
              </Flex>
              <Box flex="1" overflowX="scroll">
                <LaborTable laborItems={items ? items : []} laborTiers={tiers || []} laborServices={services || []} />
              </Box>
            </Flex>
          )}

          {/* empty state */}
          {showEmpty && (
            <>
              <Gap s="48px" />
              <EmptyBox
                title="NO LABOR PRICES SET"
                content="Now you can account for your time with SalonScale’s Labor Feature. No matter how you charge for your time, we
          will make sure you charge your worth. Click below to get started."
              >
                <Box h="24px" />
                <Button variant="round" colorScheme="brand.midnight" onClick={goToOnboard}>
                  LET'S GO!
                </Button>
              </EmptyBox>
            </>
          )}

          {/* loading state */}
          {(!tiers || !services) && (
            <Flex h="500px" align="center" justify="center">
              <Spinner />
            </Flex>
          )}
        </>
      }
    />
  )
}
export const CategoryMenuList = (props: { categories: string[] }) => {
  const { categories } = props
  const [searchText, setSearchText] = useState('')
  const filteredCategories = categories.filter(category => category.includes(searchText))
  return (
    <Box w="240px" minW="240px">
      <Text variant="default/regular/title3">Menus</Text>

      <Gap />
      <SearchFilter
        width="100%"
        theme="midnight"
        placeholder="Search"
        onChange={(text) => {
          setSearchText(text)
        }}
        value={searchText}
        onClear={() => setSearchText('')}
      />
      <Gap />
      <CategoryOption category={ALL_LABOR_CATEGORIES} showMenu={false} />

      {filteredCategories.map((category) => {
        return <CategoryOption key={category} category={category} />
      })}
    </Box>
  )
}

const CategoryOption = (props: { category: string; showMenu?: boolean }) => {
  const { category, showMenu = true } = props
  const dispatch = useDispatch()
  const selectedCategory = useAppSelector(selectSelectedLaborCategory)
  const isSelected = selectedCategory === category
  return (
    <Flex
      cursor="pointer"
      align="center"
      justify="space-between"
      h="40px"
      borderRadius="50px"
      p="12px"
      bg={isSelected ? COLORS.skylight_50 : ''}
      color={isSelected ? COLORS.skylight_500 : ''}
    >
      <Text flex="1" onClick={() => dispatch(reduceSelectedLaborCategory(category))}>
        {category}
      </Text>
      {showMenu && <CategoryMenu category={category} />}
    </Flex>
  )
}

export const CategoryMenu = (props: { category: string }) => {
  const dispatch = useDispatch()
  const { category } = props
  const { user, salonId } = UseBaseApiParams()
  return (
    <Menu>
      <MenuButton>
        <MaterialIcon name="more_vert" />
      </MenuButton>
      <MenuList>
        <MenuItem
          color="danger"
          onClick={() => {
            dispatch(
              dispatchDeleteLaborCategories({
                token: user.token,
                salonId,
                userId: user.userId,
                categories: [category],
              }),
            )
          }}
          icon={<MaterialIcon name="delete" />}
        >
          Delete Menu
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

export const CreateMenuModal = (props: { showModal: boolean; setShowModal: (show: boolean) => void }) => {
  const { showModal, setShowModal } = props
  const [categoryTitle, setCategoryTitle] = useState('')
  const [selectedType, setSelectedType] = useState('')
  const dispatch = useDispatch()
  const { user, salonId } = UseBaseApiParams()
  const onCancel = () => {
    setShowModal(false)
  }
  const onConfirm = () => {
    setShowModal(false)
    const model: APILaborServiceCreate = {
      name: categoryTitle + ' service',
      type: selectedType,
      category: categoryTitle,
    }
    dispatch(
      dispatchCreateLaborServices({
        token: user.token,
        user_id: user.userId,
        salon_id: salonId,
        models: [model],
      }),
    )
  }
  return (
    <>
      {showModal && (
        <Modal onClose={onCancel} isOpen={showModal} isCentered={true}>
          <ModalOverlay />
          <ModalContent m="12px">
            <ModalBody>
              <Flex p="36px 0 24px" direction="column">
                <Box textAlign="center" fontSize="20px" fontWeight="bold">
                  New Menu Create
                </Box>

                <Gap />

                <TextInput
                  placeholder="enter a title"
                  label="Menu Title"
                  value={categoryTitle}
                  errorText={''}
                  onBlur={(val) => {}}
                  onChange={setCategoryTitle}
                />

                <Gap />

                <SelectInput
                  placeholder="select an option"
                  label="Default Charge Type (this can be adjusted later)"
                  options={[
                    { label: 'Hourly Rate', value: 'hourly' },
                    { label: 'Flat Fee', value: 'service' },
                  ]}
                  value={selectedType}
                  errorText={''}
                  onBlur={(val) => {}}
                  onChange={setSelectedType}
                />

                <Gap />

                <Flex justify="end" gridGap="12px">
                  <Button colorScheme="brand.midnight" variant="round-ghost-upper" onClick={onCancel}>
                    Cancel
                  </Button>
                  <Button
                    disabled={selectedType === '' || categoryTitle === ''}
                    colorScheme="brand.midnight"
                    variant="round"
                    onClick={() => {
                      onConfirm()
                    }}
                  >
                    Create
                  </Button>
                </Flex>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}
