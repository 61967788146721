/////////////// slice.ts
//
//

// todos
// 1: add labor to RootState located at src/store.ts
// 2: ensure model has 'id', change it to unique property if necessary

// references
//
// slice redux docs - https://redux-toolkit.js.org/tutorials/typescript
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {assign, keyBy, keys, omit, orderBy, values} from 'lodash'

import { RootState } from '../../store'
import { LaborItem, LaborTierService, LaborService, LaborTier } from './interfaces'
import { ALL_LABOR_CATEGORIES } from "./constants";
import { groupLaborServicesByCategory } from "./utils";

// state
//
interface LaborState {
  laborTierServicesByid: { [key: string]: LaborTierService } | null
  laborTiersById: { [key: string]: LaborTier } | null
  laborServicesById: { [key: string]: LaborService } | null
  selectedLaborCategory: string
}

const initialState: LaborState = {
  laborTierServicesByid: null,
  laborTiersById: null,
  laborServicesById: null,
  selectedLaborCategory: ALL_LABOR_CATEGORIES,
}

// reducer
//
export const LaborSlice = createSlice({
  name: 'labors',
  initialState,
  reducers: {
    // labor item state
    //
    reduceListLaborItems: (state, action: PayloadAction<LaborTierService[]>) => {
      state.laborTierServicesByid = assign({}, state.laborTierServicesByid, keyBy(action.payload, 'id'))
    },
    reduceCreateLaborItems: (state, action: PayloadAction<LaborTierService[]>) => {
      state.laborTierServicesByid = assign({}, state.laborTierServicesByid, keyBy(action.payload, 'id'))
    },
    reduceUpdateLaborItems: (state, action: PayloadAction<LaborTierService[]>) => {
      state.laborTierServicesByid = assign({}, state.laborTierServicesByid, keyBy(action.payload, 'id'))
    },
    reduceDeleteLaborTierServices: (state, action: PayloadAction<LaborTierService[]>) => {
      const idsToRemove = action.payload.map((item) => item.id)
      state.laborTierServicesByid = omit(state.laborTierServicesByid, idsToRemove)
    },

    // labor tier state
    //
    reduceListLaborTiers: (state, action: PayloadAction<LaborTier[]>) => {
      state.laborTiersById = assign({}, state.laborTiersById, keyBy(action.payload, 'id'))
    },
    reduceCreateLaborTiers: (state, action: PayloadAction<LaborTier[]>) => {
      state.laborTiersById = assign({}, state.laborTiersById, keyBy(action.payload, 'id'))
    },
    reduceUpdateLaborTiers: (state, action: PayloadAction<LaborTier[]>) => {
      state.laborTiersById = assign({}, state.laborTiersById, keyBy(action.payload, 'id'))
    },
    reduceDeleteLaborTiers: (state, action: PayloadAction<LaborTier[]>) => {
      const idsToRemove = action.payload.map((item) => item.id)
      state.laborTiersById = omit(state.laborTiersById, idsToRemove)
    },

    // labor service state
    //
    reduceListLaborServices: (state, action: PayloadAction<LaborService[]>) => {
      state.laborServicesById = assign({}, state.laborServicesById, keyBy(action.payload, 'id'))
    },
    reduceCreateLaborServices: (state, action: PayloadAction<LaborService[]>) => {
      state.laborServicesById = assign({}, state.laborServicesById, keyBy(action.payload, 'id'))
    },
    reduceUpdateLaborServices: (state, action: PayloadAction<LaborService[]>) => {
      state.laborServicesById = assign({}, state.laborServicesById, keyBy(action.payload, 'id'))
    },
    reduceDeleteLaborServices: (state, action: PayloadAction<LaborService[]>) => {
      const idsToRemove = action.payload.map((item) => item.id)
      state.laborServicesById = omit(state.laborServicesById, idsToRemove)
    },
    reduceSelectedLaborCategory: (state, action: PayloadAction<string>) => {
      state.selectedLaborCategory = action.payload
    },
  },
})

// actions
//
export const {
  reduceListLaborItems,
  reduceCreateLaborItems,
  reduceUpdateLaborItems,
  reduceDeleteLaborTierServices,
  reduceListLaborTiers,
  reduceUpdateLaborTiers,
  reduceCreateLaborTiers,
  reduceDeleteLaborTiers,
  reduceListLaborServices,
  reduceCreateLaborServices,
  reduceUpdateLaborServices,
  reduceDeleteLaborServices,
  reduceSelectedLaborCategory,
} = LaborSlice.actions

// selectors
//
export const selectLaborItemList = (state: RootState): LaborItem[] | null => {
  // if they have no labor tier services return empty list (not null)
  if (state.labors.laborTierServicesByid && keys(state.labors.laborTierServicesByid).length === 0) {
    return []
  }
  const items = values(state.labors.laborTierServicesByid) || []
  const tiers = state.labors.laborTiersById || {}
  const services = state.labors.laborServicesById || {}

  const updatedItems: LaborItem[] = []
  // only return items if all three objects exist
  if (items.length > 0 && keys(tiers).length > 0 && keys(services).length > 0) {
    items.forEach((item) => {
      const itemTier = tiers[item.tierId]
      const itemService = services[item.serviceId]
      const updatedItem = assign({}, item, { tier: itemTier, service: itemService })
      updatedItems.push(updatedItem)
    })
    const property1Sorter = (labor) => labor?.tier?.name.toLowerCase()
    const property2Sorter = (labor) => labor?.service?.name.toLowerCase()
    return orderBy(updatedItems, [property1Sorter, property2Sorter], ['asc', 'asc'])
  }
  return null
}

export const selectLaborServiceList = (state: RootState): LaborService[] | null => {
  const property1Sorter = (service) => service?.name?.toLowerCase()
  const property2Sorter = (service) => service?.type?.toLowerCase()
  return state.labors.laborServicesById
    ? orderBy(values(state.labors.laborServicesById), [property1Sorter, property2Sorter], ['asc', 'asc'])
    : null
}

export const selectLaborTierServicesForTier = (state: RootState, tier: LaborTier): LaborTierService[] | null => {
  const allItems = values(state.labors.laborTierServicesByid) || []
  const itemsForTier = allItems.filter((item) => item.tierId === tier.id)
  return itemsForTier
}

export const selectLaborTierServicesForService = (
  state: RootState,
  service: LaborService,
): LaborTierService[] | null => {
  const allItems = values(state.labors.laborTierServicesByid) || []
  const itemsForService = allItems.filter((item) => item.serviceId === service.id)
  return itemsForService
}

export const selectLaborTierList = (state: RootState): LaborTier[] | null => {
  const property1Sorter = (tier) => tier?.name?.toLowerCase()
  return state.labors.laborTiersById ? orderBy(values(state.labors.laborTiersById), [property1Sorter], ['asc']) : null
}

export const selectSelectedLaborCategory = (state: RootState): string => {
  return state.labors.selectedLaborCategory
}

export const selectLaborServicesByCategory = (state: RootState): { [category: string]: LaborService[] } | null => {
  return state.labors.laborServicesById
    ? groupLaborServicesByCategory(values(state.labors.laborServicesById))
    : null
}
// export
//
export default LaborSlice.reducer
