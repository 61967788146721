/////////////// mappers.ts
//
//

// todos: copy paste Labor and labor

import { map } from 'lodash'
import {
  APILaborItem,
  APILaborService,
  APILaborTier,
  LaborItem,
  LaborTierService,
  LaborService,
  LaborTier,
  APIUserLaborTier,
  UserLaborTier,
} from './interfaces'
import { centsToDollars } from '../../core/money/utils'
import { MOCK_CATEGORIES } from "./constants";

export const mapAPILaborItemToLaborTierService = (api: APILaborItem): LaborTierService => {
  return {
    id: api.id,
    priceDollars: centsToDollars(api.price), // convert price from cents to dollars
    tierId: api.tier.id,
    serviceId: api.service.id,
  }
}

export const mapAPILaborItemsToLaborTierServices = (apiModels: APILaborItem[]): LaborTierService[] => {
  return map(apiModels, mapAPILaborItemToLaborTierService)
}

export const mapLaborItemToLaborTierService = (model: LaborItem): LaborTierService => {
  return {
    id: model.id,
    priceDollars: model.priceDollars,
    tierId: model.tier.id,
    serviceId: model.service.id,
  }
}
export const mapLaborItemsToLaborTierServices = (models: LaborItem[]): LaborTierService[] => {
  return map(models, mapLaborItemToLaborTierService)
}

export const mapAPILaborTierToLaborTier = (api: APILaborTier): LaborTier => {
  return {
    id: api.id,
    name: api.name,
  }
}

export const mapAPILaborTiersToLaborTiers = (apiModels: APILaborTier[]): LaborTier[] => {
  return map(apiModels, mapAPILaborTierToLaborTier)
}

export const mapAPILaborServiceToLaborService = (api: APILaborService): LaborService => {

  // todo: replace with actual category when api exists
  const mockCategory = api.category || MOCK_CATEGORIES[Math.floor(Math.random() * MOCK_CATEGORIES.length)]

  return {
    id: api.id,
    name: api.name,
    type: api.type,
    category: mockCategory
  }
}

export const mapAPILaborServicesToLaborServices = (apiModels: APILaborService[]): LaborService[] => {
  return map(apiModels, mapAPILaborServiceToLaborService)
}

export const mapAPIUserLaborTierToUserLaborTier = (api: APIUserLaborTier): UserLaborTier => {
  return {
    id: api.tier_id,
    userId: api.user_id,
    name: api.tier_name ? api.tier_name : '',
  }
}

export const mapAPIUserLaborTierToUserLaborTiers = (apiModels: APIUserLaborTier[]): UserLaborTier[] => {
  return map(apiModels, mapAPIUserLaborTierToUserLaborTier)
}

