/////////////// api.ts
//
//

import axios, { AxiosResponse } from 'axios'
import { Dispatch } from '@reduxjs/toolkit'

import {
  reduceActiveSessionColors,
  reduceActiveSessionExtensions,
  reduceActiveSessionMeta,
  reduceActiveSessionPhotos,
  reduceActiveSessionSupplies,
  reduceCompleteSessionLara,
  reduceCreateActiveSessionPhoto,
  reduceCreateSessionLara,
  reduceDeleteActiveSession,
  reduceDeleteActiveSessionColor,
  reduceDeleteActiveSessionExtension,
  reduceDeleteActiveSessionPhoto,
  reduceDeleteActiveSessionSupply,
  reduceListBowlTypes,
  reduceListSessionsLara,
  reduceRedirectToSessionId, reduceSessionPhotosForClient,
  reduceSessionsLaraPagination,
} from './slice'
import {
  APIBowlType,
  APICreateOrUpdateSessionColorLara,
  APICreateOrUpdateSessionExtensionLara,
  APICreateOrUpdateSessionSupplyLara,
  APICreateSessionPhotoLara,
  APISessionColorLara,
  APISessionExtensionLara,
  APISessionMetaCreateLara,
  APISessionMetaLara,
  APISessionMetaPatchLara,
  APISessionPhotoLara,
  APISessionSupplyLara,
  BowlType,
  SessionColorLara,
  SessionExtensionLara,
  SessionMetaLara,
  SessionPhotoLara,
  SessionSupplyLara,
} from './interfaces'
import {
  mapAPIBowlTypesToBowlTypes,
  mapAPISessionColorsLaraToSessionColorsLara,
  mapAPISessionExtensionsLaraToSessionExtensionsLara,
  mapAPISessionLaraToSessionLara,
  mapAPISessionPhotoLaraToSessionPhotoLara,
  mapAPISessionPhotosLaraToSessionPhotosLara,
  mapAPISessionsLaraToSessionsLara,
  mapAPISessionSuppliesLaraToSessionSuppliesLara,
} from './mappers'
import { GetServerBaseUrl } from '../../env'
import { reduceSetLoadingState } from '../../core/loading/slice'
import { assign } from 'lodash'
import { Client } from '../clients/interfaces'
import { reduceCreateClient } from '../clients/slice'
import { apiCreateClient } from '../clients/api'
import { apiWrapper } from '../../mini-lib/api-utils/apiWrapper'
import {
  APIPagedLaraResponse,
  buildLaraConfig,
  buildLaraPageParams,
  buildLaraStringFilter,
  LaraPagination,
  mapAPILaraPaginationToLaraPagination
} from "../../mini-lib/lara/lara-utils";
import { buildDateYYYYMMDD } from "../../core/dates";
import { LOADING_SESSIONS } from "./constants";

// session apis
//
//
//
export const apiGetBowlTypes = (params: { token: string }): Promise<BowlType[]> => {
  const {token} = params
  const url = `bowl-types/`
  return apiWrapper({
    version: 'v3',
    server: 'lara',
    method: 'get',
    token,
    url,
  }).then((response: AxiosResponse<{ data: APIBowlType[] }>) => {
    return mapAPIBowlTypesToBowlTypes(response.data.data)
  })
}

// lara sessions
//
//
//
//

export const dispatchGetBowlTypes = (params: { token: string }) => {
  return (dispatch: Dispatch) => {
    return apiGetBowlTypes(params)
      .then((resp) => {
        dispatch(reduceListBowlTypes(resp))
      })
      .catch((error) => {
        throw error
      })
  }
}


export const apiListSessionsLara = (params: {
  token: string;
  salonId: number,
  clientId?: number,
  userId?: number,
  startDateTime?: Date,
  endDateTime?: Date,
  pageSize?: number,
  pageNumber?: number,
}): Promise<{ models: SessionMetaLara[]; pagination: LaraPagination }> => {
  const {token, salonId, pageNumber = 1, clientId, userId, startDateTime, endDateTime, pageSize = 30} = params
  const startsBetween = startDateTime && endDateTime ? `${buildDateYYYYMMDD(startDateTime)},${buildDateYYYYMMDD(endDateTime)}` : undefined

  const startsBetweenFilter = buildLaraStringFilter({key: 'starts_between', value: startsBetween, encode: false})
  const clientIdFilter = buildLaraStringFilter({key: 'client_id', value: clientId, encode: false})
  const userIdFilter = buildLaraStringFilter({key: 'user_id', value: userId, encode: false})

  const config = buildLaraConfig({token})
  const {pageSizeParam, pageNumberParam} = buildLaraPageParams({pageSize: pageSize, pageNumber})

  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/sessions/?${pageSizeParam}&${pageNumberParam}${clientIdFilter}${userIdFilter}${startsBetweenFilter}`

  return axios
    .get(url, config)
    .then((response: { data: APIPagedLaraResponse }) => {
      return {
        pagination: mapAPILaraPaginationToLaraPagination(response.data.meta),
        models: mapAPISessionsLaraToSessionsLara(response.data.data),
      }
    })
    .catch((error) => {
      throw error
    })
}

export const apiCreateSessionLara = (params: {
  token: string
  salonId: number
  model: APISessionMetaCreateLara
}): Promise<SessionMetaLara> => {
  const {token, salonId, model} = params

  const config = buildLaraConfig({token})
  const body = model
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/sessions/`

  return axios
    .post(url, body, config)
    .then((response: { data: {data: APISessionMetaLara} }) => {
      return mapAPISessionLaraToSessionLara(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiBulkCreateSessionLara = (params: {
  token: string
  salonId: number
  models: APISessionMetaCreateLara[]
}): Promise<SessionMetaLara[]> => {
  const {token, salonId, models} = params

  const config = buildLaraConfig({token})
  const body = {
    sessions: models
  }
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/bulk-sessions/`

  return axios
    .post(url, body, config)
    .then((response: { data: {data: APISessionMetaLara[]} }) => {
      return mapAPISessionsLaraToSessionsLara(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiCreateOrUpdateSessionSuppliesLara = (params: {
  token: string
  salonId: number
  sessionId: number
  models: APICreateOrUpdateSessionSupplyLara[]
}): Promise<SessionSupplyLara[]> => {
  const {token, salonId, sessionId, models} = params

  const config = buildLaraConfig({token})
  const body = models
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/sessions/${sessionId}/session-supplies`

  return axios
    .post(url, body, config)
    .then((response: { data: {data: APISessionSupplyLara[]} }) => {
      return mapAPISessionSuppliesLaraToSessionSuppliesLara(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiDeleteSessionSupplyLara = (params: {
  token: string
  salonId: number
  sessionId: number
  sessionSupplyId: number
}): Promise<void> => {
  const {token, salonId, sessionId, sessionSupplyId} = params

  const config = buildLaraConfig({token})
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/sessions/${sessionId}/session-supplies/${sessionSupplyId}`

  return axios
    .delete(url, config)
    .then(() => {
      return
    })
    .catch((error) => {
      throw error
    })
}

export const apiCreateOrUpdateSessionExtensionsLara = (params: {
  token: string
  salonId: number
  sessionId: number
  models: APICreateOrUpdateSessionExtensionLara[]
}): Promise<SessionExtensionLara[]> => {
  const {token, salonId, sessionId, models} = params

  const config = buildLaraConfig({token})
  const body = models
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/sessions/${sessionId}/extension-items`

  return axios
    .post(url, body, config)
    .then((response: { data: {data: APISessionExtensionLara[]} }) => {
      return mapAPISessionExtensionsLaraToSessionExtensionsLara(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiDeleteSessionExtensionLara = (params: {
  token: string
  salonId: number
  sessionId: number
  sessionExtensionIds: number[]
}): Promise<void> => {
  const {token, salonId, sessionId, sessionExtensionIds} = params

  const config = buildLaraConfig({token})
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/sessions/${sessionId}/extension-items/`
  // note: axios is weird about adding a body
  // ref: https://stackoverflow.com/a/53263784
  config['data'] = {
    sessionitem_id: sessionExtensionIds
  }
  return axios
    .delete(url, config)
    .then(() => {
      return
    })
    .catch((error) => {
      throw error
    })
}

export const apiListSessionExtensionsLara = (params: {
  token: string
  salonId: number
  sessionId: number
}): Promise<SessionExtensionLara[]> => {
  const {token, salonId, sessionId} = params

  const config = buildLaraConfig({token})
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/sessions/${sessionId}/extension-items`

  return axios
    .get(url, config)
    .then((response: { data: {data: APISessionExtensionLara[]} }) => {
      return mapAPISessionExtensionsLaraToSessionExtensionsLara(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}


export const apiSessionMetaPatchLara = ( params: {
  token: string
  salonId: number
  sessionId: number
  model: APISessionMetaPatchLara
}): Promise<SessionMetaLara> => {
  const {token, salonId, sessionId, model} = params

  const config = buildLaraConfig({token})
  const body = model
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/sessions/${sessionId}`

  return axios
    .patch(url, body, config)
    .then((response: { data: {data: APISessionMetaLara} }) => {
      return mapAPISessionLaraToSessionLara(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiListSessionSuppliesLara = (params: {
  token: string
  salonId: number
  sessionId: number
}): Promise<SessionSupplyLara[]> => {
  const {token, salonId, sessionId} = params

  const config = buildLaraConfig({token})
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/sessions/${sessionId}/session-supplies`

  return axios
    .get(url, config)
    .then((response: { data: {data: APISessionSupplyLara[]} }) => {
      return mapAPISessionSuppliesLaraToSessionSuppliesLara(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiGetSessionMetaLara = (params: {
  token: string
  salonId: number
  sessionId: number
}): Promise<SessionMetaLara> => {
  const {token, salonId, sessionId} = params

  const config = buildLaraConfig({token})
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/sessions/${sessionId}`

  return axios
    .get(url, config)
    .then((response: { data: {data: APISessionMetaLara} }) => {
      return mapAPISessionLaraToSessionLara(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiDeleteSessionMetaLara = (params: {
  token: string
  salonId: number
  sessionId: number
}): Promise<void> => {
  const {token, salonId, sessionId} = params

  const config = buildLaraConfig({token})
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/sessions/${sessionId}`

  return axios
    .delete(url, config)
    .then(() => {return} )
    .catch((error) => {
      throw error
    })
}

export const apiCreateOrUpdateSessionColorsLara = (params: {
  token: string
  salonId: number
  sessionId: number
  models: APICreateOrUpdateSessionColorLara[]
}): Promise<SessionColorLara[]> => {
  const {token, salonId, sessionId, models} = params

  const config = buildLaraConfig({token})
  const body = models
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/sessions/${sessionId}/color-items`

  return axios
    .post(url, body, config)
    .then((response: { data: {data: APISessionColorLara[]} }) => {
      return mapAPISessionColorsLaraToSessionColorsLara(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiDeleteSessionColorLara = (params: {
  token: string
  salonId: number
  sessionId: number
  sessionColorIds: number[]
}): Promise<void> => {
  const {token, salonId, sessionId, sessionColorIds} = params

  const config = buildLaraConfig({token})
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/sessions/${sessionId}/color-items/`
  // note: axios is weird about adding a body
  // ref: https://stackoverflow.com/a/53263784
  config['data'] = {
    sessionitem_id: sessionColorIds
  }
  return axios
    .delete(url, config)
    .then(() => {
      return
    })
    .catch((error) => {
      throw error
    })
}

export const apiListSessionColorsLara = (params: {
  token: string
  salonId: number
  sessionId: number
}): Promise<SessionColorLara[]> => {
  const {token, salonId, sessionId} = params

  const config = buildLaraConfig({token})
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/sessions/${sessionId}/color-items`

  return axios
    .get(url, config)
    .then((response: { data: {data: APISessionColorLara[]} }) => {
      return mapAPISessionColorsLaraToSessionColorsLara(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}


export const apiListSessionPhotosLara = (params: {
  token: string
  salonId: number
  sessionId: number
}): Promise<SessionPhotoLara[]> => {
  const {token, salonId, sessionId} = params

  const config = buildLaraConfig({token})
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/sessions/${sessionId}/session-photo`

  return axios
    .get(url, config)
    .then((response: { data: {data: APISessionPhotoLara[]} }) => {
      return mapAPISessionPhotosLaraToSessionPhotosLara(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}
export const apiCreateSessionPhotoLara = (params: {
  token: string
  salonId: number
  sessionId: number
  model: APICreateSessionPhotoLara
}): Promise<SessionPhotoLara> => {
  const {token, salonId, sessionId, model} = params

  const config = buildLaraConfig({token})
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/sessions/${sessionId}/session-photo/`
  const body = model
  return axios
    .post(url, body, config)
    .then((response: { data: {data: APISessionPhotoLara} }) => {
      return mapAPISessionPhotoLaraToSessionPhotoLara(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}
export const apiDeleteSessionPhotoLara = (params: {
  token: string
  salonId: number
  sessionId: number
  photoId: number
}): Promise<void> => {
  const {token, salonId, sessionId, photoId} = params

  const config = buildLaraConfig({token})
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/sessions/${sessionId}/session-photo/${photoId}`

  return axios
    .delete(url, config)
    .then(() => {
      return
    })
    .catch((error) => {
      throw error
    })
}

export const apiCompleteSessionLara = (params: {
  token: string
  salonId: number
  sessionId: number
}): Promise<any> => {
  const {token, salonId, sessionId} = params

  const config = buildLaraConfig({token})
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/sessions/${sessionId}/complete`

  return axios
    .post(url, null, config)
    .then((resp) => {
      return resp
    })
    .catch((error) => {
      throw error
    })
}

export const apiListAllSessionPhotosLara = (params: {
  token: string
  salonId: number
  sessionId?: number
  clientId?: number
}): Promise<any> => {
  const {token, salonId, clientId} = params
  // todo: add this in once the api supports session id filtering
  // const sessionIdParam = sessionId ? `&filter[session_id]=${sessionId}` : ''
  const clientIdParam = clientId ? `&filter[client_id]=${clientId}` : ''
  const config = buildLaraConfig({token})
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/session-photos/?${clientIdParam}`

  return axios
    .get(url, config)
    .then((resp) => {
      return mapAPISessionPhotosLaraToSessionPhotosLara(resp.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const dispatchListSessionsLara = (params: {
  token: string;
  salonId: number,
  clientId?: number,
  userId?: number,
  startDateTime?: Date,
  endDateTime?: Date,
  pageSize?: number,
  pageNumber?: number
  loadingName?: string
}) => {
  const {loadingName = LOADING_SESSIONS} = params
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({name: loadingName, state: true}))
    return apiListSessionsLara(params).then((resp) => {
      dispatch(reduceListSessionsLara(resp.models))
      dispatch(reduceSessionsLaraPagination(resp.pagination))
      dispatch(reduceSetLoadingState({name: loadingName, state: false}))
    })
  }
}

export const dispatchCreateSessionLara = (params: {
  token: string
  salonId: number
  model: APISessionMetaCreateLara
  loadingName?: string
}) => {
  const {loadingName = 'creating-session'} = params
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({name: loadingName, state: true}))
    return apiCreateSessionLara(params).then((resp) => {
      dispatch(reduceCreateSessionLara(resp))
      dispatch(reduceRedirectToSessionId(resp.id))
      dispatch(reduceSetLoadingState({name: loadingName, state: false}))
    })
  }
}

export const dispatchBulkCreateSessionsLara = (params: {
  token: string
  salonId: number
  models: APISessionMetaCreateLara[]
  loadingName?: string
}) => {
  const {loadingName = 'creating-session'} = params
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({name: loadingName, state: true}))
    return apiBulkCreateSessionLara(params).then((resp) => {
      dispatch(reduceListSessionsLara(resp))
      dispatch(reduceSetLoadingState({name: loadingName, state: false}))
    })
  }
}

export const dispatchPatchSessionMetaLara = (params: {
  token: string
  salonId: number
  sessionId: number
  model: APISessionMetaPatchLara
  loadingName?: string
}) => {
  const {loadingName = 'updating-session'} = params
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({name: loadingName, state: true}))
    return apiSessionMetaPatchLara(params).then(( resp) => {
      dispatch(reduceActiveSessionMeta(resp))
      dispatch(reduceSetLoadingState({name: loadingName, state: false}))
    })
  }
}

export const dispatchGetSessionMetaLara = (params: {
  token: string
  salonId: number
  sessionId: number
  loadingName?: string
}) => {
  const {loadingName = 'getting-session'} = params
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({name: loadingName, state: true}))
    return apiGetSessionMetaLara(params).then((resp) => {
      dispatch(reduceActiveSessionMeta(resp))
      dispatch(reduceRedirectToSessionId(resp.id))
      dispatch(reduceSetLoadingState({name: loadingName, state: false}))
    })
  }
}
export const dispatchDeleteSessionMetaLara = ( params: {
  token: string
  salonId: number
  sessionId: number
  loadingName?: string
} ) => {
  return ( dispatch: Dispatch ) => {
    return apiDeleteSessionMetaLara(params)
      .then(( resp ) => {
        dispatch(reduceDeleteActiveSession(params.sessionId))
      })
      .catch(( error ) => {
        throw error
      })
  }
}


export const dispatchCreateClientAndSessionLara = (params: {
  token: string
  salonId: number
  session: APISessionMetaCreateLara
  client: Client
  loadingName?: string
}) => {
  const {token, salonId, client, session} = params
  return (dispatch: Dispatch) => {
    return apiCreateClient({token, salonId, model: client})
      .then((resp) => {
        dispatch(reduceCreateClient(resp))
        const sessionWithClient = assign({}, session, {client_id: resp.id})
        return apiCreateSessionLara({token, salonId, model: sessionWithClient})
          .then((resp) => {
             dispatch(reduceCreateSessionLara(resp))
            dispatch(reduceRedirectToSessionId(resp.id))
          })
          .catch((error) => {
            throw error
          })
      })
      .catch((error) => {
        throw error
      })
  }
}


// session supplies
//
//
export const dispatchListSessionSuppliesLara = (params: {
  token: string;
  salonId: number,
  sessionId: number
  loadingName?: string
}) => {
  const {loadingName = 'loading-session-supplies'} = params
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({name: loadingName, state: true}))
    return apiListSessionSuppliesLara(params).then((resp) => {
      dispatch(reduceActiveSessionSupplies(resp))
      dispatch(reduceSetLoadingState({name: loadingName, state: false}))
    })
  }
}


export const dispatchCreateOrUpdateSessionSuppliesLara = (params: {
  token: string;
  salonId: number,
  sessionId: number
  models: APICreateOrUpdateSessionSupplyLara[]
  loadingName?: string
}) => {
  const {loadingName = 'creating-session-supplies'} = params
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({name: loadingName, state: true}))
    return apiCreateOrUpdateSessionSuppliesLara(params).then((resp) => {
      dispatch(reduceActiveSessionSupplies(resp))
      dispatch(reduceSetLoadingState({name: loadingName, state: false}))
    })
  }
}

export const dispatchDeleteSessionSupplyLara = ( params: {
  token: string;
  salonId: number;
  sessionId: number,
  sessionSupplyId: number
} ) => {
  return ( dispatch: Dispatch ) => {
    return apiDeleteSessionSupplyLara(params)
      .then(( resp ) => {
        dispatch(reduceDeleteActiveSessionSupply(params.sessionSupplyId))
      })
      .catch(( error ) => {
        throw error
      })
  }
}

// session extensions
//
//
export const dispatchListSessionExtensionsLara = (params: {
  token: string;
  salonId: number,
  sessionId: number
  loadingName?: string
}) => {
  const {loadingName = 'loading-extension-items'} = params
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({name: loadingName, state: true}))
    return apiListSessionExtensionsLara(params).then((resp) => {
      dispatch(reduceActiveSessionExtensions(resp))
      dispatch(reduceSetLoadingState({name: loadingName, state: false}))
    })
  }
}



export const dispatchCreateOrUpdateSessionExtensionsLara = (params: {
  token: string;
  salonId: number,
  sessionId: number
  models: APICreateOrUpdateSessionExtensionLara[]
  loadingName?: string
}) => {
  const {loadingName = 'creating-extension-items'} = params
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({name: loadingName, state: true}))
    return apiCreateOrUpdateSessionExtensionsLara(params).then((resp) => {
      dispatch(reduceActiveSessionExtensions(resp))
      dispatch(reduceSetLoadingState({name: loadingName, state: false}))
    })
  }
}

export const dispatchDeleteSessionExtensionLara = ( params: {
  token: string;
  salonId: number;
  sessionId: number,
  sessionExtensionIds: number[]
} ) => {
  return ( dispatch: Dispatch ) => {
    return apiDeleteSessionExtensionLara(params)
      .then(( resp ) => {
        dispatch(reduceDeleteActiveSessionExtension(params.sessionExtensionIds))
      })
      .catch(( error ) => {
        throw error
      })
  }
}


// session colors
//
//
export const dispatchListSessionColorsLara = (params: {
  token: string;
  salonId: number,
  sessionId: number
  loadingName?: string
}) => {
  const {loadingName = 'listing-color-items'} = params
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({name: loadingName, state: true}))
    return apiListSessionColorsLara(params).then((resp) => {
      dispatch(reduceActiveSessionColors(resp))
      dispatch(reduceSetLoadingState({name: loadingName, state: false}))
    })
  }
}


export const dispatchCreateOrUpdateSessionColorsLara = (params: {
  token: string;
  salonId: number,
  sessionId: number
  models: APICreateOrUpdateSessionColorLara[]
  loadingName?: string
}) => {
  const {loadingName = 'creating-color-items'} = params
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({name: loadingName, state: true}))
    return apiCreateOrUpdateSessionColorsLara(params).then((resp) => {
      dispatch(reduceActiveSessionColors(resp))
      dispatch(reduceSetLoadingState({name: loadingName, state: false}))
    })
  }
}

export const dispatchDeleteSessionColorLara = ( params: {
  token: string;
  salonId: number;
  sessionId: number,
  sessionColorIds: number[]
} ) => {
  return ( dispatch: Dispatch ) => {
    return apiDeleteSessionColorLara(params)
      .then(( resp ) => {
        dispatch(reduceDeleteActiveSessionColor(params.sessionColorIds))
      })
      .catch(( error ) => {
        throw error
      })
  }
}


// session photos lara
export const dispatchListSessionPhotosLara = (params: {
  token: string;
  salonId: number,
  sessionId: number
  loadingName?: string
}) => {
  const {loadingName = 'loading-photos'} = params
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({name: loadingName, state: true}))
    return apiListSessionPhotosLara(params).then((resp) => {
      dispatch(reduceActiveSessionPhotos(resp))
      dispatch(reduceSetLoadingState({name: loadingName, state: false}))
    })
  }
}

export const dispatchDeleteSessionPhotoLara = (params: {
  token: string;
  salonId: number,
  sessionId: number
  photoId: number
  loadingName?: string
}) => {
  const {loadingName = 'deleting-photo'} = params
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({name: loadingName, state: true}))
    return apiDeleteSessionPhotoLara(params).then((resp) => {
      dispatch(reduceDeleteActiveSessionPhoto(params.photoId))
      dispatch(reduceSetLoadingState({name: loadingName, state: false}))
    })
  }
}

export const dispatchCreateSessionPhotoLara = (params: {
  token: string;
  salonId: number,
  sessionId: number
  model: APICreateSessionPhotoLara
  loadingName?: string
}) => {
  const {loadingName = 'creating-photo'} = params
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({name: loadingName, state: true}))
    return apiCreateSessionPhotoLara(params).then((resp) => {
      dispatch(reduceCreateActiveSessionPhoto(resp))
      dispatch(reduceSetLoadingState({name: loadingName, state: false}))
    })
  }
}

export const dispatchCompleteSessionLara = ( params: {
  token: string;
  salonId: number;
  sessionId: number;
} ) => {
  return ( dispatch: Dispatch ) => {
    return apiCompleteSessionLara(params)
      .then(( resp ) => {
        dispatch(reduceCompleteSessionLara(params.sessionId))
      })
      .catch(( error ) => {
        throw error
      })
  }
}

export const dispatchListAllSessionPhotosLara = ( params: {
  token: string
  salonId: number
  sessionId?: number
  clientId?: number
} ) => {
  return ( dispatch: Dispatch ) => {
    return apiListAllSessionPhotosLara(params)
      .then(( resp ) => {
        dispatch(reduceSessionPhotosForClient(resp))
      })
      .catch(( error ) => {
        throw error
      })
  }
}

