import {
  Box,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import {
  APILaborServiceUpdate,
  APILaborTierCreate,
  APILaborTierServiceCreate,
  APILaborTierServiceUpdate,
  APILaborTierUpdate,
  LABOR_SERVICE_TYPES,
  LaborItem,
  LaborService,
  LaborTier,
} from '../../data/labor/interfaces'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useAppSelector } from '../../hooks'
import { selectLoggedInUser, selectMemberList } from '../../data/user/slice'
import {
  dispatchCreateLaborServices,
  dispatchCreateLaborTiers,
  dispatchCreateLaborTierServices,
  dispatchDeleteLaborServices,
  dispatchDeleteLaborTiers,
  dispatchDeleteLaborTierServices,
  dispatchDuplicateLaborService,
  dispatchDuplicateLaborTier,
  dispatchUpdateLaborServices,
  dispatchUpdateLaborTiers,
  dispatchUpdateLaborTierServices,
} from '../../data/labor/api'
import { buildLoadingIdentifier, buildTierServiceKey } from '../../data/labor/utils'
import { MaterialIcon } from '../../mini-lib/icons/MaterialIcon'
import { SavedStateIcon } from '../../mini-lib/icons/SaveStateIcon'
import { dispatchSetLoadingType } from '../../core/loading/api'
import { selectLaborTierServicesForService, selectLaborTierServicesForTier } from '../../data/labor/slice'
import { LaborTierUsers } from './LaborTierUsers'
import { DEFAULT_SERVICE_NAME, DEFAULT_TIER_NAME } from '../../data/labor/constants'
import { dollarsToCents } from '../../core/money/utils'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'


export const LaborServiceCell = (props: { service: LaborService; theme: string }) => {
  const dispatch = useDispatch()
  const {
    user: { token, userId },
    salonId,
  } = UseBaseApiParams()
  const { service, theme } = props
  const laborTierServicesForService = useAppSelector((state) => selectLaborTierServicesForService(state, service))

  const deleteService = (service: LaborService) => {
    dispatch(
      dispatchDeleteLaborServices({
        token: token,
        user_id: userId,
        salon_id: salonId,
        models: [service],
      }),
    )
  }
  const updateService = (service: LaborService, updatedName: string) => {
    const updatedService: APILaborServiceUpdate = { id: service.id, name: updatedName, type: service.type }
    if (updatedName !== service.name) {
      dispatch(
        dispatchUpdateLaborServices({
          token: token,
          user_id: userId,
          salon_id: salonId,
          models: [updatedService],
        }),
      )
    }
  }
  const duplicateService = (service: LaborService) => {
    if (laborTierServicesForService) {
      dispatch(
        dispatchDuplicateLaborService({
          token: token,
          user_id: userId,
          salon_id: salonId,
          service: service,
          tierServicesForService: laborTierServicesForService,
        }),
      )
    }
  }
  return (
    <Flex
      justify="space-between"
      align="center"
      h="100%"
      w="100%"
      bg={`brand.${theme}.100`}
      color="black"
      borderLeftColor={`brand.${theme}.500`}
      borderLeftWidth="9px"
      pl="12px"
    >
      <Tooltip label={service.name}>
        <Editable
          defaultValue={service.name}
          onSubmit={(updatedName) => updateService(service, updatedName)}
          isTruncated={true}
          w="100%"
        >
          <EditablePreview cursor="pointer" w="100%" />
          <EditableInput w="100%" />
        </Editable>
      </Tooltip>
      <ServiceMenu service={service} deleteService={deleteService} duplicateService={duplicateService} />
    </Flex>
  )
}

export const LaborServiceAddCell = (props: { serviceType: string; theme: string }) => {
  const params: any = useParams()
  const dispatch = useDispatch()
  const { salonId } = params
  const { serviceType, theme } = props
  const user = useAppSelector(selectLoggedInUser)
  const createService = (type: string) => {
    if (user && type) {
      dispatch(
        dispatchCreateLaborServices({
          token: user.token,
          user_id: user.userId,
          salon_id: salonId,
          models: [{ name: DEFAULT_SERVICE_NAME, type: type }],
        }),
      )
    }
  }
  return (
    <Flex
      onClick={() => createService(serviceType)}
      cursor="pointer"
      align="center"
      h="100%"
      borderColor={`brand.${theme}.500`}
      borderWidth="1px"
      borderRadius="8px"
      borderLeftColor={`brand.${theme}.500`}
      borderLeftWidth="9px"
      p="12px"
    >
      + Add Item
    </Flex>
  )
}
export const LaborTierServiceCell = (props: {
  tier: LaborTier
  service: LaborService
  laborItemsByTierService: { [key: string]: LaborItem }
  type: string
}) => {
  const { tier, service, laborItemsByTierService, type } = props
  const dispatch = useDispatch()
  const params: any = useParams()
  const { salonId } = params
  const user = useAppSelector(selectLoggedInUser)
  const loadingIdentifier = buildLoadingIdentifier('item', tier.id, service.id)
  const key = buildTierServiceKey(tier.id, service.id)
  const itemForTierService = laborItemsByTierService[key]
  const inputValue: any = itemForTierService ? `${itemForTierService?.priceDollars}` : ''
  const [error, setError] = useState('')

  const createOrUpdateItem = (updatedPrice: any) => {
    if (updatedPrice === '') {
      deleteTierService()
    } else if (isNaN(updatedPrice) || updatedPrice < 0) {
      setError('invalid number')
    } else {
      setError('')
      if (itemForTierService && user && updatedPrice !== itemForTierService.priceDollars.toString()) {
        dispatch(dispatchSetLoadingType({ name: loadingIdentifier, state: true }))
        const updatedItem: APILaborTierServiceUpdate = {
          id: itemForTierService.id,
          tier_id: tier.id,
          service_id: service.id,
          price: dollarsToCents(updatedPrice),
        }
        dispatch(
          dispatchUpdateLaborTierServices({
            token: user.token,
            user_id: user.userId,
            salon_id: salonId,
            loadingId: loadingIdentifier,
            models: [updatedItem],
          }),
        )
      } else if (!itemForTierService && user) {
        dispatch(dispatchSetLoadingType({ name: loadingIdentifier, state: true }))
        const createdItem: APILaborTierServiceCreate = {
          tier_id: tier.id,
          service_id: service.id,
          price: dollarsToCents(updatedPrice),
        }
        dispatch(
          dispatchCreateLaborTierServices({
            token: user.token,
            user_id: user.userId,
            salon_id: salonId,
            loadingId: loadingIdentifier,
            models: [createdItem],
          }),
        )
      }
    }
  }
  const deleteTierService = () => {
    if (user && itemForTierService) {
      dispatch(dispatchSetLoadingType({ name: loadingIdentifier, state: true }))
      dispatch(
        dispatchDeleteLaborTierServices({
          token: user.token,
          user_id: user.userId,
          salon_id: salonId,
          loadingId: loadingIdentifier,
          models: [itemForTierService],
        }),
      )
    }
  }

  return (
    <Flex
      p={inputValue === '' ? '' : '0 12px'}
      justify="space-between"
      align="center"
      h="100%"
      borderColor="shades.neutral.200"
      borderWidth="1px"
      borderRadius="8px"
    >
      <SavedStateIcon loadingName={loadingIdentifier} />
      <Flex align="center">
        <Editable
          key={inputValue}
          textAlign="end"
          defaultValue={inputValue}
          placeholder={inputValue === '' ? 'add price' : ''}
          onSubmit={(updatedPrice) => createOrUpdateItem(updatedPrice)}
          w="100%"
        >
          <EditablePreview cursor="pointer" color={inputValue ? 'inherit' : 'shades.neutral.500'} w="100%" />
          <EditableInput w="100%" />
        </Editable>
        {!error &&
          itemForTierService &&
          itemForTierService.priceDollars >= 0 &&
          type === LABOR_SERVICE_TYPES.hourly && <Box pl="4px">/hr</Box>}
        {error && (
          <Popover>
            <PopoverTrigger>
              <IconButton
                ml="4px"
                color="danger"
                aria-label="error"
                variant="ghost"
                size="xs"
                icon={<MaterialIcon size="16px" name="error_outline" />}
              />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>Please enter a number</PopoverBody>
            </PopoverContent>
          </Popover>
        )}
        {!error && inputValue === '' && (
          <Popover>
            <PopoverTrigger>
              <IconButton
                m="0 4px"
                color="danger"
                aria-label="error"
                variant="ghost"
                size="xs"
                icon={<MaterialIcon size="16px" color="shades.neutral.400" name="visibility_off" />}
              />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>This will not appear in the app</PopoverBody>
            </PopoverContent>
          </Popover>
        )}
      </Flex>
    </Flex>
  )
}

export const LaborTierAddCell = (props: {}) => {
  const dispatch = useDispatch()
  const params: any = useParams()
  const { salonId } = params
  const user = useAppSelector(selectLoggedInUser)

  const addTier = () => {
    const tier: APILaborTierCreate = { name: DEFAULT_TIER_NAME }
    if (user) {
      dispatch(
        dispatchCreateLaborTiers({
          token: user.token,
          user_id: user.userId,
          salon_id: salonId,
          models: [tier],
        }),
      )
    }
  }
  return (
    <Flex pl="12px" gridGap="8px" align="center" h="100%" color="brand.lavender.500" onClick={addTier} cursor="pointer">
      <MaterialIcon name="add_circle" /> Add Tier
    </Flex>
  )
}

export const LaborTierCell = (props: { tier: LaborTier }) => {
  const dispatch = useDispatch()
  const params: any = useParams()
  const { tier } = props
  const { salonId } = params
  const user = useAppSelector(selectLoggedInUser)
  const laborTierServicesForTier = useAppSelector((state) => selectLaborTierServicesForTier(state, tier))
  const salonMembers = useAppSelector(selectMemberList)

  const deleteTier = (tier: LaborTier) => {
    if (user) {
      dispatch(
        dispatchDeleteLaborTiers({
          token: user.token,
          user_id: user.userId,
          salon_id: salonId,
          models: [tier],
        }),
      )
    }
  }
  const updateTier = (tier: LaborTier, updatedName: string) => {
    const updatedTier: APILaborTierUpdate = { id: tier.id, name: updatedName }
    if (user && updatedName !== tier.name) {
      dispatch(
        dispatchUpdateLaborTiers({
          token: user.token,
          user_id: user.userId,
          salon_id: salonId,
          models: [updatedTier],
        }),
      )
    }
  }
  const duplicateTier = (tier: LaborTier) => {
    if (user && laborTierServicesForTier) {
      dispatch(
        dispatchDuplicateLaborTier({
          token: user.token,
          user_id: user.userId,
          salon_id: salonId,
          tier: tier,
          tierServicesForTier: laborTierServicesForTier,
        }),
      )
    }
  }
  return (
    <Flex pl="12px" justify="space-between" align="center" h="100%" bg="brand.midnight.50" color="black" width="100%">
      <Tooltip label={tier.name}>
        <Editable defaultValue={tier.name} onSubmit={(updatedName) => updateTier(tier, updatedName)} isTruncated={true}>
          <EditablePreview cursor="pointer" />
          <EditableInput />
        </Editable>
      </Tooltip>
      <Flex>
        {salonMembers && <LaborTierUsers tier={tier} salonMembers={salonMembers} />}
        <TierMenu tier={tier} deleteTier={deleteTier} duplicateTier={duplicateTier} />
      </Flex>
    </Flex>
  )
}

export const ServiceMenu = (props: {
  service: LaborService
  deleteService: (LaborService) => void
  duplicateService: (LaborService) => void
}) => {
  const { service, deleteService, duplicateService } = props
  return (
    <Menu>
      <MenuButton as={IconButton} aria-label="Options" icon={<MaterialIcon name="more_vert" />} variant="ghost" />
      <MenuList>
        <MenuItem
          onClick={() => {
            duplicateService(service)
          }}
          icon={<MaterialIcon name="content_copy" />}
        >
          Duplicate Service
        </MenuItem>
        <MenuItem
          color="danger"
          onClick={() => {
            deleteService(service)
          }}
          icon={<MaterialIcon name="delete" />}
        >
          Delete Service
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

export const TierMenu = (props: {
  tier: LaborTier
  deleteTier: (LaborTier) => void
  duplicateTier: (LaborTier) => void
}) => {
  const { tier, deleteTier, duplicateTier } = props
  return (
    <Menu>
      <MenuButton>
        <MaterialIcon name="more_vert" />
      </MenuButton>
      <MenuList>
        <MenuItem
          onClick={() => {
            duplicateTier(tier)
          }}
          icon={<MaterialIcon name="content_copy" />}
        >
          Duplicate Tier
        </MenuItem>
        <MenuItem
          color="danger"
          onClick={() => {
            deleteTier(tier)
          }}
          icon={<MaterialIcon name="delete" />}
        >
          Delete Tier
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
