/////////////// api.ts
//
//

import axios, {AxiosResponse} from 'axios'
import {Dispatch} from '@reduxjs/toolkit'

import {
  reduceCreateLaborItems,
  reduceCreateLaborServices,
  reduceCreateLaborTiers,
  reduceDeleteLaborTierServices,
  reduceDeleteLaborServices,
  reduceDeleteLaborTiers,
  reduceListLaborItems,
  reduceListLaborServices,
  reduceListLaborTiers,
  reduceUpdateLaborItems,
  reduceUpdateLaborServices,
  reduceUpdateLaborTiers, selectLaborServicesByCategory,
} from './slice'
import {
  APILaborItem,
  APILaborService,
  APILaborServiceCreate,
  APILaborServiceUpdate,
  APILaborTier,
  APILaborTierCreate,
  APILaborTierServiceCreate,
  APILaborTierServiceUpdate,
  APILaborTierUpdate,
  LaborItem,
  LaborService,
  LaborTier,
  LaborTierService,
} from './interfaces'
import {
  mapAPILaborItemsToLaborTierServices,
  mapAPILaborServicesToLaborServices,
  mapAPILaborTiersToLaborTiers,
} from './mappers'
import { delay } from 'lodash'
import { reduceSetLoadingState } from '../../core/loading/slice'
import { dollarsToCents } from '../../core/money/utils'
import { GetServerBaseUrl } from '../../env'
import { RootState } from "../../store";

// labor item apis
//
//
export const apiListLaborItems = (
  token: string,
  salon_id: number,
  pageNumber = 1,
  searchText = '',
): Promise<LaborTierService[]> => {
  const url = `${GetServerBaseUrl()}/labor-items/?token=${token}&salon_id=${salon_id}&page=${pageNumber}`
  return axios
    .get(url)
    .then((response: AxiosResponse<{ data: APILaborItem[] }>) => {
      return mapAPILaborItemsToLaborTierServices(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiUpdateLaborItems = (params: {
  token: string
  user_id: number
  salon_id: number
  models: APILaborTierServiceUpdate[]
}): Promise<LaborTierService[]> => {
  const { token, user_id, salon_id, models } = params
  const url = `${GetServerBaseUrl()}/labor-items/?token=${token}&user_id=${user_id}&salon_id=${salon_id}`
  const body = {
    token,
    owner_id: user_id,
    salon_id: salon_id,
    labor_items: models,
  }
  return axios
    .put(url, body)
    .then((response: any) => {
      return mapAPILaborItemsToLaborTierServices(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}
export const apiCreateLaborItems = (params: {
  token: string
  user_id: number
  salon_id: number
  models: APILaborTierServiceCreate[]
}): Promise<LaborTierService[]> => {
  const { token, user_id, salon_id, models } = params
  const url = `${GetServerBaseUrl()}/labor-items/?token=${token}&salon_id=${salon_id}&user_id=${user_id}`
  const body = {
    token,
    owner_id: user_id,
    salon_id: salon_id,
    labor_items: models,
  }
  return axios
    .post(url, body)
    .then((response: any) => {
      return mapAPILaborItemsToLaborTierServices(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}
export const apiDeleteLaborTierServices = (params: {
  token: string
  user_id: number
  salon_id: number
  models: LaborItem[]
}): Promise<any> => {
  const { token, user_id, salon_id, models } = params
  const modelIds = models.map((model) => model.id).join(',')
  const url = `${GetServerBaseUrl()}/labor-items/?token=${token}&salon_id=${salon_id}&user_id=${user_id}&ids=${modelIds}`
  return axios
    .delete(url)
    .then((response: any) => {
      return models
    })
    .catch((error) => {
      throw error
    })
}


// labor item actions
//
//
export const dispatchListLaborItems = (token: string, salon_id: number, pageNumber = 1, searchText?: string) => {
  return (dispatch: Dispatch) => {
    return apiListLaborItems(token, salon_id, pageNumber, searchText).then((resp) => {
      dispatch(reduceListLaborItems(resp))
    })
  }
}
export const dispatchUpdateLaborTierServices = (params: {
  token: string
  user_id: number
  salon_id: number
  loadingId: string
  models: APILaborTierServiceUpdate[]
}) => {
  return (dispatch: Dispatch) => {
    return apiUpdateLaborItems(params)
      .then((resp) => {
        dispatch(reduceUpdateLaborItems(resp))
        delay(() => {
          dispatch(reduceSetLoadingState({name: params.loadingId, state: false}))
        }, 2000)
      })
      .catch((error) => {
        throw error
      })
  }
}
export const dispatchCreateLaborTierServices = (params: {
  token: string
  user_id: number
  salon_id: number
  loadingId: string
  models: APILaborTierServiceCreate[]
}) => {
  return (dispatch: Dispatch) => {
    return apiCreateLaborItems(params)
      .then((resp) => {
        dispatch(reduceCreateLaborItems(resp))
        delay(() => {
          dispatch(reduceSetLoadingState({ name: params.loadingId, state: false }))
        }, 2000)
      })
      .catch((error) => {
        throw error
      })
  }
}
export const dispatchDeleteLaborTierServices = (params: {
  token: string
  user_id: number
  salon_id: number
  loadingId: string
  models: LaborItem[]
}) => {
  return (dispatch: Dispatch) => {
    return apiDeleteLaborTierServices(params)
      .then((resp) => {
        dispatch(reduceDeleteLaborTierServices(resp))
        delay(() => {
          dispatch(reduceSetLoadingState({ name: params.loadingId, state: false }))
        }, 2000)
      })
      .catch((error) => {
        throw error
      })
  }
}

// labor tier apis
//
//
export const apiListLaborTiers = (token: string, salon_id: number, pageNumber = 1, searchText = ''): Promise<LaborTier[]> => {
  const url = `${GetServerBaseUrl()}/labor-tiers/?token=${token}&salon_id=${salon_id}&page=${pageNumber}`
  return axios
    .get(url)
    .then((response: AxiosResponse<{ data: APILaborTier[] }>) => {
      return mapAPILaborTiersToLaborTiers(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiUpdateLaborTiers = (params: {
  token: string
  user_id: number
  salon_id: number
  models: APILaborTierUpdate[]
}): Promise<LaborTier[]> => {
  const { token, user_id, salon_id, models } = params
  const url = `${GetServerBaseUrl()}/labor-tiers/?token=${token}&user_id=${user_id}&salon_id=${salon_id}`
  const body = {
    token,
    owner_id: user_id,
    salon_id: salon_id,
    labor_tiers: models,
  }
  return axios
    .put(url, body)
    .then((response: any) => {
      return mapAPILaborTiersToLaborTiers(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}
export const apiCreateLaborTiers = (params: {
  token: string
  user_id: number
  salon_id: number
  models: APILaborTierCreate[]
}): Promise<LaborTier[]> => {
  const { token, user_id, salon_id, models } = params
  const url = `${GetServerBaseUrl()}/labor-tiers/?token=${token}&salon_id=${salon_id}&user_id=${user_id}`
  const body = {
    token,
    owner_id: user_id,
    salon_id: salon_id,
    labor_tiers: models,
  }
  return axios
    .post(url, body)
    .then((response: any) => {
      return mapAPILaborTiersToLaborTiers(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}
export const apiDeleteLaborTiers = (params: {
  token: string
  user_id: number
  salon_id: number
  models: LaborTier[]
}): Promise<any> => {
  const { token, user_id, salon_id, models } = params
  const modelIds = models.map((model) => model.id).join(',')
  const url = `${GetServerBaseUrl()}/labor-tiers/?token=${token}&salon_id=${salon_id}&user_id=${user_id}&ids=${modelIds}`
  return axios
    .delete(url)
    .then((response: any) => {
      return models
    })
    .catch((error) => {
      throw error
    })
}


// labor tier actions
//
//
export const dispatchListLaborTiers = (token: string, salon_id: number, pageNumber = 1, searchText?: string) => {
  return (dispatch: Dispatch) => {
    return apiListLaborTiers(token, salon_id, pageNumber, searchText).then((resp) => {
      dispatch(reduceListLaborTiers(resp))
    })
  }
}
export const dispatchUpdateLaborTiers = (params: {
  token: string
  user_id: number
  salon_id: number
  models: APILaborTierUpdate[]
}) => {
  return (dispatch: Dispatch) => {
    return apiUpdateLaborTiers(params)
      .then((resp) => {
        dispatch(reduceUpdateLaborTiers(resp))
      })
      .catch((error) => {
        throw error
      })
  }
}
export const dispatchCreateLaborTiers = (params: {
  token: string
  user_id: number
  salon_id: number
  models: APILaborTierCreate[]
}) => {
  return (dispatch: Dispatch) => {
    return apiCreateLaborTiers(params)
      .then((resp) => {
        dispatch(reduceCreateLaborTiers(resp))
      })
      .catch((error) => {
        throw error
      })
  }
}
export const dispatchDuplicateLaborTier = (params: {
  token: string
  user_id: number
  salon_id: number
  tier: LaborTier
  tierServicesForTier: LaborTierService[]
}) => {
  const { token, user_id, salon_id, tier, tierServicesForTier } = params
  const copyTier: APILaborTierCreate = { name: `${tier.name} copy` }
  return (dispatch: Dispatch) => {
    return apiCreateLaborTiers({
      token,
      user_id,
      salon_id,
      models: [copyTier],
    })
      .then((resp) => {
        const copyItems: APILaborTierServiceCreate[] = tierServicesForTier.map((tierService) => {
          return {
            tier_id: resp[0].id,
            service_id: tierService.serviceId,
            price: dollarsToCents(tierService.priceDollars),
          }
        })
        dispatch(reduceCreateLaborTiers(resp))
        dispatch(dispatchCreateLaborTierServices({ token, user_id, salon_id, loadingId: '', models: copyItems }) as any)
      })
      .catch((error) => {
        throw error
      })
  }
}
export const dispatchDeleteLaborTiers = (params: {
  token: string
  user_id: number
  salon_id: number
  models: LaborTier[]
}) => {
  return (dispatch: Dispatch) => {
    return apiDeleteLaborTiers(params)
      .then((resp) => {
        dispatch(reduceDeleteLaborTiers(resp))
      })
      .catch((error) => {
        throw error
      })
  }
}

// labor service apis
//
//
export const apiListLaborServices = (
  token: string,
  salon_id: number,
  pageNumber = 1,
  searchText = '',
): Promise<LaborService[]> => {
  const url = `${GetServerBaseUrl()}/labor-services/?token=${token}&salon_id=${salon_id}&page=${pageNumber}`
  return axios
    .get(url)
    .then((response: AxiosResponse<{ data: APILaborService[] }>) => {
      return mapAPILaborServicesToLaborServices(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiUpdateLaborServices = (params: {
  token: string
  user_id: number
  salon_id: number
  models: APILaborServiceUpdate[]
}): Promise<LaborService[]> => {
  const { token, user_id, salon_id, models } = params
  const url = `${GetServerBaseUrl()}/labor-services/?token=${token}&user_id=${user_id}&salon_id=${salon_id}`
  const body = {
    token,
    owner_id: user_id,
    salon_id: salon_id,
    labor_services: models,
  }
  return axios
    .put(url, body)
    .then((response: any) => {
      return mapAPILaborServicesToLaborServices(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}
export const apiCreateLaborServices = (params: {
  token: string
  user_id: number
  salon_id: number
  models: APILaborServiceCreate[]
}): Promise<LaborService[]> => {
  const { token, user_id, salon_id, models } = params
  const url = `${GetServerBaseUrl()}/labor-services/?token=${token}&salon_id=${salon_id}&user_id=${user_id}`
  const body = {
    salon_id: salon_id,
    labor_services: models,
  }
  return axios
    .post(url, body)
    .then((response: any) => {
      return mapAPILaborServicesToLaborServices(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}
export const apiDeleteLaborServices = (params: {
  token: string
  user_id: number
  salon_id: number
  models: LaborService[]
}): Promise<any> => {
  const { token, user_id, salon_id, models } = params
  const modelIds = models.map((model) => model.id).join(',')
  const url = `${GetServerBaseUrl()}/labor-services/?token=${token}&salon_id=${salon_id}&user_id=${user_id}&ids=${modelIds}`
  return axios
    .delete(url)
    .then((response: any) => {
      return models
    })
    .catch((error) => {
      throw error
    })
}

// labor tier actions
//
//
export const dispatchListLaborServices = (token: string, salon_id: number, pageNumber = 1, searchText?: string) => {
  return (dispatch: Dispatch) => {
    return apiListLaborServices(token, salon_id, pageNumber, searchText).then((resp) => {
      dispatch(reduceListLaborServices(resp))
    })
  }
}
export const dispatchUpdateLaborServices = (params: {
  token: string
  user_id: number
  salon_id: number
  models: APILaborServiceUpdate[]
}) => {
  return (dispatch: Dispatch) => {
    return apiUpdateLaborServices(params)
      .then((resp) => {
        dispatch(reduceUpdateLaborServices(resp))
      })
      .catch((error) => {
        throw error
      })
  }
}
export const dispatchCreateLaborServices = (params: {
  token: string
  user_id: number
  salon_id: number
  models: APILaborServiceCreate[]
}) => {
  return (dispatch: Dispatch) => {
    return apiCreateLaborServices(params)
      .then((resp) => {
        dispatch(reduceCreateLaborServices(resp))
      })
      .catch((error) => {
        throw error
      })
  }
}
export const dispatchDeleteLaborServices = (params: {
  token: string
  user_id: number
  salon_id: number
  models: LaborService[]
}) => {
  return (dispatch: Dispatch) => {
    return apiDeleteLaborServices(params)
      .then((resp) => {
        dispatch(reduceDeleteLaborServices(resp))
      })
      .catch((error) => {
        throw error
      })
  }
}

export const dispatchCreateLaborServicesAndLaborTierServices = (params: {
  token: string
  user_id: number
  salon_id: number
  tiers: LaborTier[]
  services: APILaborServiceCreate[]
  tierPriceMap: { [key: string]: number }
}) => {
  return (dispatch: Dispatch) => {
    const { token, user_id, salon_id, tiers, services, tierPriceMap } = params
    return apiCreateLaborServices({ token, user_id, salon_id, models: services })
      .then((resp) => {
        const tierServicesToCreate: APILaborTierServiceCreate[] = []
        tiers.forEach((tier) => {
          const priceForTier = tierPriceMap[tier.id]
          resp.forEach((service) => {
            tierServicesToCreate.push({
              tier_id: tier.id,
              service_id: service.id,
              price: priceForTier ? dollarsToCents(priceForTier) : 0,
            })
          })
        })
        dispatch(reduceDeleteLaborServices(resp))
        dispatch(
          dispatchCreateLaborTierServices({
            token,
            user_id,
            salon_id,
            loadingId: '',
            models: tierServicesToCreate,
          }) as any,
        )
      })
      .catch((error) => {
        throw error
      })
  }
}
export const dispatchDuplicateLaborService = (params: {
  token: string
  user_id: number
  salon_id: number
  service: LaborService
  tierServicesForService: LaborTierService[]
}) => {
  const { token, user_id, salon_id, service, tierServicesForService } = params
  const copyService: APILaborServiceCreate = { name: `${service.name} copy`, type: service.type }
  return (dispatch: Dispatch) => {
    return apiCreateLaborServices({
      token,
      user_id,
      salon_id,
      models: [copyService],
    })
      .then((resp) => {
        const copyItems: APILaborTierServiceCreate[] = tierServicesForService.map((tierService) => {
          return {
            tier_id: tierService.tierId,
            service_id: resp[0].id,
            price: dollarsToCents(tierService.priceDollars),
          }
        })
        dispatch(reduceCreateLaborServices(resp))
        dispatch(dispatchCreateLaborTierServices({ token, user_id, salon_id, loadingId: '', models: copyItems }) as any)
      })
      .catch((error) => {
        throw error
      })
  }
}


// stubs
export const dispatchDeleteLaborCategories = (params: {
  token: string
  userId: number
  salonId: number
  categories: string[]
}) => {
  const {
    // token,
    // userId,
    // salonId,
    categories
  } = params

  return (dispatch: Dispatch | any, getState: () => RootState) => {
    const state = getState()
    const laborServicesByCategory = selectLaborServicesByCategory(state)
    const laborServicesForCategory = laborServicesByCategory ? laborServicesByCategory[ categories[ 0 ] ] : []
    // todo: uncomment below for actual deletion
    console.log({laborServicesForCategory})
    // dispatch(dispatchDeleteLaborServices({token, user_id: userId, salon_id: salonId, models: laborServicesForCategory}))
  }
}