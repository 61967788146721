import { isDateInRange, isDateInRangeInclusive, MONTHS } from '../utils/dates'
import { UseQueryParams } from '../utils/basic'
import { useFlags } from 'launchdarkly-react-client-sdk'

export const ReleaseSmsInviteUSA = (): boolean => {
  const { smsInviteUsa = false } = useFlags() || {}
  return smsInviteUsa
}

export const ReleaseSearchAnything = (): boolean => {
  const { searchAnything = false } = useFlags() || {}
  return searchAnything
}
export const ReleaseBulkClients = (): boolean => {
  const { bulkClientsWeb = false } = useFlags() || {}
  return bulkClientsWeb
}

export const ReleaseMasterProducts = (): boolean => {
  const { masterProductsWeb = false } = useFlags() || {}
  return masterProductsWeb
}

export const ReleaseLaraSupplies = (): boolean => {
  const { laravelSupplies = false } = useFlags() || {}
  return laravelSupplies
}

export const ReleaseExtensionsV2 = (): boolean => {
  const { extensionsV2 = false } = useFlags() || {}
  return extensionsV2
}

export const ReleaseStartGuideV2 = (): boolean => {
  const { startGuideV2 = false } = useFlags() || {}
  return startGuideV2
}

export const ReleasePageHelp = (): boolean => {
  const { pageHelp = false } = useFlags() || {}
  return pageHelp
}

export const ReleaseMultiLocation = (): boolean => {
  const { multiLocation = false } = useFlags() || {}
  return multiLocation
}
export const ReleaseLaraColors = (): boolean => {
  const { laraColors = false } = useFlags() || {}
  return laraColors
}
export const ReleaseInventoryOrders = (): boolean => {
  const { inventoryOrders = false } = useFlags() || {}
  return inventoryOrders
}

export const ReleasePerfectPair = (): boolean => {
  const promotionEndDate = new Date(2022, MONTHS.december, 1, 0)
  const today = new Date()
  // if today is before the end date they are allowed to access the promotion
  return today.getTime() <= promotionEndDate.getTime()
}

export const ReleaseBlackFriday = (): boolean => {
  const promotionStartDate = new Date(2022, MONTHS.november, 24, 0)
  const promotionEndDate = new Date(2022, MONTHS.november, 29, 0)
  const now = new Date()
  return isDateInRange({ date: now, start: promotionStartDate, end: promotionEndDate })
}

export const ReleaseQuickTrial = (): boolean => {
  const { quickTrialWeb = false } = useFlags() || {}
  return quickTrialWeb
}

export const ReleaseSpecialPricing = (): boolean => {
  const { specialPricing = false } = useFlags() || {}
  const currentDate = new Date()
  // const inDateRange = _isDateInBlackFridayDateRange(currentDate)
  const inDateRange = _isDateInHolidayDateRange(currentDate)
  return specialPricing || inDateRange
}
export const _isDateInBlackFridayDateRange = (currentDate: Date) => {
  const promotionStartDate = new Date(2023, MONTHS.november, 24, 0)
  const promotionEndDate = new Date(2023, MONTHS.november, 27, 0)
  return isDateInRangeInclusive({ date: currentDate, start: promotionStartDate, end: promotionEndDate })
}
export const _isDateInHolidayDateRange = (currentDate: Date) => {
  const promotionStartDate = new Date(2023, MONTHS.december, 19, 0)
  const promotionEndDate = new Date(2023, MONTHS.december, 31, 0)
  return isDateInRangeInclusive({ date: currentDate, start: promotionStartDate, end: promotionEndDate })
}
export const ReleaseBoxingDay = (): boolean => {
  const queryParams = UseQueryParams()
  const dev = queryParams.get('boxingday')
  if (dev) {
    return true
  }
  const promotionStartDate = new Date(2022, MONTHS.december, 25, 0)
  const promotionEndDate = new Date(2022, MONTHS.december, 31, 0)
  const now = new Date()
  return isDateInRangeInclusive({ date: now, start: promotionStartDate, end: promotionEndDate })
}
export const ReleaseCakeDay = (): boolean => {
  const promotionStartDate = new Date(2023, MONTHS.june, 8, 0)
  const promotionEndDate = new Date(2023, MONTHS.june, 12, 0)
  const now = new Date()
  return isDateInRangeInclusive({ date: now, start: promotionStartDate, end: promotionEndDate })
}

export const ReleaseLabour = (): boolean => {
  const { labour = false } = useFlags() || {}
  return labour
}

export const ReleaseUnitsSupport = (): boolean => {
  const { unitsSupport = false } = useFlags() || {}
  return unitsSupport
}

export const MixpanelEnabled = (): boolean => {
  const { mixpanel = false } = useFlags() || {}
  return mixpanel
}

export const ReleaseTutorialsV2 = (): boolean => {
  const { tutorialsV2Web = false } = useFlags() || {}
  return tutorialsV2Web
}

export const ReleaseSquare = (): boolean => {
  const { square = false } = useFlags() || {}
  return square
}

export const ReleaseOpenDay = (): boolean => {
  const { openDay = false } = useFlags() || {}
  return openDay
}
